import logo from '../img/logo.png';

function App() {
    return (
        <div className="min-h-screen bg-background">
            <Avatar />
            <Message />
        </div >
    );
}

function Avatar() {
    return (
        <img src={logo} className="p-7 mx-auto size-60" alt="Raijin logo" />
    );
}

function Message() {
    return (
        <h2 className='mx-auto justify-center flex items-center text-center box-border text-white h-24 w-96 border-4 rounded-lg bg-purple border-purple'>Currently under maintenance</h2>
    );
}

export default App;
